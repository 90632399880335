import React, { Component, Fragment } from "react";
import { FormControl, Row, Col, FormGroup, Button, Form } from "react-bootstrap";
import { faEdit, faCheck, faBan, faSpinner, faPlus, faImage, faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDepartments } from "../services/DepartmentService";
import { updateTeam, createTeam } from "../services/TeamService";
import { getDepartmentTypes } from "../services/DepartmentTypeService";
import { getUsersIdentity, setUserAvatar } from "../services/UserService";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { fetchUser } from '../redux/services/UserService';
import { fetchTeams, selectTeam } from '../redux/services/TeamService';
import displayErrorToast from "../components/utils/displayErrorToast";
import SubHeaderComponent from "../components/SubHeaderComponent";
import CreatableSelect from 'react-select/creatable'
import { createUser } from "../services/UserService";
import AWS from 'aws-sdk'
import axios from 'axios';
import LabelComponent from "../components/utils/getCompanyLabel";
import * as DefaultLabels from '../assets/glossary.json';

// icons
import Delete from '../assets/ButtonIcons/Delete Blue.svg';
import Loader from '../assets/ButtonIcons/Loader Blue.svg';
import Constants from "../utils/constants";
const labels = DefaultLabels.default;

class TeamIdentityScreen extends Component {

  state = {
    loading: false,
    loadType: "",
    edit: false,
    modified: false,
    departments: [],
    departmentTypes: [],
    users: [],
    error: "",
    team: [],
    userLoading: false,
    userType: "",
    logo: null,
    editNicknames: [],
    editableUsers: [],
    userAvatars: [],
    loadingId: null
  }

  constructor(props) {
    super(props)

    this.props.toggleDirtyState(true)
    this.props.togglePageLoad(true)
    this.getImageText = this.getImageText.bind(this)
  }

  async componentDidMount() {
    AWS.config.update(Constants.AWS)
    try {
      await this.fetchDepartments()
      await this.fetchUsers()
      await this.fetchDepartmentTypes()
      this.props.togglePageLoad(false)
    } catch (error) {
      this.setState({
        error: error.message
      })
    }

  }

  async fetchDepartmentTypes() {
    const response = await getDepartmentTypes()

    this.setState({
      departmentTypes: response.data.departmentTypes
    })
  }

  getLabelText = key => <LabelComponent val={key}/>

  getText = val => {
    const { user } = this.props;

    const companyId = localStorage.getItem("companyId");
    let lang = "EN";
    let langLabels = labels.find(lbl => lbl.lang === lang);
    let def = langLabels.glossary.find(label => label.key === val);

    if(user && user.companies && user.companies.length) {
      const company = user.companies.find(company => company.id === companyId);
      if(company) {
        lang = company.language;
        langLabels = labels.find(lbl => lbl.lang === lang);
        def = langLabels.glossary.find(label => label.key === val);
        if(!def) {
          lang = "EN";
          langLabels = labels.find(lbl => lbl.lang === lang);
          def = langLabels.glossary.find(label => label.key === val);
        }
      }
      if(company && company.companyGlossaries) {
        const custom = company.companyGlossaries.find(label => label.key === val);

        if(custom) return custom.value
      }
    }

    if(!def) {
      console.log(val)
    }
    return def.value
  }

  createUser = async (name, value) => {
    if(!value.includes(",")) {
      toast.error(this.getLabelText("error_separate_names"))

      return false
    }

    this.setState({
      userLoading: true,
      userType: name
    })
    const names = value.split(",");

    const response = await createUser({
      firstNames: names[0],
      lastName: names[1],
      role: name === "members" ? "Team Member" : name === "coach" ? "Coach" : "Team Leader",
      isAdmin: false
    })

    await this.fetchUsers()

    this.handleArray(name, response.data.createUser.id)
    this.setState({
      userLoading: false,
      userType: ""
    })
  }



  getArrayIds = (array) => {
    let ids = [];
    array.map((item) => {
      ids.push(item.id)
      return true
    });
    return ids;
  }

  isAllowed = (name, id) => {
    const { team } = this.state;
    const leaderIds = this.getArrayIds(team.leaders)
    const memberIds = this.getArrayIds(team.members)
    if(team.coach) {
      leaderIds.push(team.coach.id)
    }
    if (name === "members") {
      return !leaderIds.includes(id)
    } else {
      return !memberIds.includes(id)
    }
  }

  async fetchUsers() {
    const response = await getUsersIdentity()
    this.setState({
      users: response.data.company.users.filter(user => !user.archived).sort((a, b) => a.firstNames.localeCompare(b.firstNames))
    })
  }

  async fetchDepartments() {
    const response = await getDepartments()

    if (response.data.departments) {
      this.setState({
        departments: this.flattenDepartments(response.data.departments)
      })
    }
  }

  flattenDepartments = (array) => {
    let departments = [];
    array.some(function iter(a) {
      departments.push(a)
      return Array.isArray(a.children) && a.children.some(iter);
    });
    return departments;
  }

  getDepartmentList = (array) => {
    let options = []

    array.sort((a,b) => a.name.localeCompare(b.name)).map((option, index) => {
      if((option.id === this.props.selectedTeam.department.id && this.state.team.id !== "") || option.shiftCount > option.teamLength) {
        if (option.shiftCount > 1) {
          for(let i = 1; i <= option.shiftCount; i++) {
            if((option.id === this.props.selectedTeam.department.id && this.state.team.id !== "" && i === option.teamLength) || i > option.teamLength) {
              options.push(
                <option key={option.id + "-" + index} value={option.id}>
                  {option.name + (
                    (option.id === this.props.selectedTeam.department.id && option.teamLength !== i) || option.id !== this.props.selectedTeam.department.id ? " - " + i : "")
                  }
                </option>
              )
            }
          }
        } else {
          options.push(<option key={option.id + "-" + index} value={option.id}>{option.name}</option>)
        }
      }

      return true
    })

    return options
  }

  uploadImage = async (files, user = null) => {
    this.setState({
      loading: true,
      loadingType: "upload",
      loadingId: user ? user.id : "logo"
    })
    const file = files[0];
    const s3 = new AWS.S3();
    const s3_bucket = 'pre.production.assets.cdi.fluenty.co.za'
    const file_name = file.name + new Date().getTime().toString()
    const file_type = file.type

    // Set up the payload of what we are sending to the S3 api.
    const s3Params = {
      Bucket: s3_bucket,
      Key: file_name,
      Expires: 500,
      ContentType: file_type,
      ACL: 'public-read'
    };

    // Make a request to the S3 API to get a signed URL
    // which we can use to upload our file.
    s3.getSignedUrl('putObject', s3Params, async (err, data) => {
      if(err){
        console.log(err)
      }
      // Data payload of what we are sending back, the url of the signedRequest and a URL
      // where we can access the content after its saved.
      const url = `https://${s3_bucket}.s3.amazonaws.com/`
      const res = {
        signedRequest: data,
        url: url
      };


      // Put the file type in the headers for the upload.
      let options = {
        headers: {
          'Content-Type': file_type
        }
      }
      await axios.put(res.signedRequest, file, options)
        .then(() => {
          let saveOptions = {
            modified: true
          }

          if(user) {
            let userAvatars = this.state.userAvatars

            let obj = {
              userId: user.id,
              avatar: 'https://d32jclxwlibaks.cloudfront.net/' + file_name
            }

            const index = userAvatars.findIndex(avatar => avatar.teamId === this.state.team.id && avatar.userId === user.id)
            if(index > -1) {
              userAvatars[index] = obj
            } else {
              userAvatars.push(obj)
            }

            saveOptions = {
              ...saveOptions,
              userAvatars
            }
          } else {
            saveOptions = {
              ...saveOptions,
              logo: 'https://d32jclxwlibaks.cloudfront.net/' + file_name
            }
          }

          this.setState(saveOptions)
        })
        .catch(error => {
          console.log(error)
          console.log(JSON.stringify(error));
        })
        .finally(() => {
          this.setState({
            loading: false,
            loadingType: "",
            loadingId: null
          })
        })

    })

  }

  getImageText = (data, type) => {
    const s3_bucket = 'd32jclxwlibaks.cloudfront.net'
    const { loading, loadingType, loadingId } = this.state

    if(type === "avatar") {
      const { userAvatars } = this.state

      if(loading && loadingType === "upload" && loadingId === data.id) {
        return <label className="custom-file-label"><img src={Loader} alt="Loader" className="loader-spinner"/></label>
      } else {
        if(data) {
          const newAvatar = userAvatars.find(avatar => avatar.userId === data.id)

          if(newAvatar) {
            return <label className="custom-file-label">{newAvatar.avatar.replace(`https://${s3_bucket}/`, ``)}</label>
          } else if(data.avatar && data.avatar !== "") {
            return <label className="custom-file-label">{data.avatar.replace(`https://${s3_bucket}/`, ``)}</label>
          } else {
            return <label className="custom-file-label">{this.getLabelText("select_avatar")}</label>
          }
        } else {
          return <label className="custom-file-label">{this.getLabelText("select_avatar")}</label>
        }
      }
    } else {
      if(loading && loadingType === "upload" && loadingId === "logo") {
        return <label className="custom-file-label"><img src={Loader} alt="Loader" className="loader-spinner"/></label>
      } else {
        if(data && data !== "") {
          return <label className="custom-file-label">{data.replace(`https://${s3_bucket}/`, ``)}</label>
        } else {
          return <label className="custom-file-label">{this.getLabelText("select_logo")}</label>
        }
      }
    }
  }

  getField = (name, type, data, placeholder, array = null) => {
    const { edit, create } = this.state
    // Determine if input fields should be displayed or text fields
    if (edit || create) {
      switch (type) {
        case "input":
          // generate input field with given data.
          return (
            <FormControl
              autoFocus
              type="text"
              className="form-control"
              placeholder={placeholder}
              autoComplete="off"
              name={name}
              value={data}
              onChange={(e) => this.handleChange(e)}
            />
          )
        case "select":
          // generate select box with given input.
          if(name === "department") {
            return (
              <select className="form-control cdi-ddl w-100 ml-0"
                name={name}
                value={data && data !== "null" ? data : 1}
                onChange={(e) => this.handleChange(e)}
              >
                <option value="1" disabled>{placeholder}</option>
                {this.getDepartmentList(array)}
              </select>
            )
          } else if(name === "coach") {
            return (
              <table className="w-100">
                <tbody>
                  <tr>
                    <td width="50%">
                      <div className="custom-file">
                        <input type="file" className={"custom-file-input" + (data ? "" : " disabled")} name={name} onChange={(e) => this.uploadImage(e.target.files, data)} accept="image/*"/>
                        {this.getImageText(data, "avatar")}
                      </div>
                    </td>
                    <td width="50%">
                      <select className="form-control cdi-ddl w-100 ml-0"
                        name={name}
                        value={data && data !== "null" && data.id ? data.id : 1}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <option value="1" disabled>{placeholder}</option>
                        {array.sort((a,b) => a.name ? a.name.localeCompare(b.name) : a.firstNames.localeCompare(b.firstNames)).map((option) => {
                          if (name !== "coach" || this.isAllowed(name, option.id)) {
                            return <option key={option.id} value={option.id}>{option.name ? option.name : option.firstNames + " " + option.lastName}</option>
                          }

                          return false
                        })}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          } else {
            return (
              <select className="form-control cdi-ddl w-100 ml-0"
                name={name}
                value={data && data !== "null" ? data : 1}
                onChange={(e) => this.handleChange(e)}
              >
                <option value="1" disabled>{placeholder}</option>
                {array.sort((a,b) => a.name ? a.name.localeCompare(b.name) : a.firstNames.localeCompare(b.firstNames)).map((option) => {
                  if (name !== "coach" || this.isAllowed(name, option.id)) {
                    return <option key={option.id} value={option.id}>{option.name ? option.name : option.firstNames + " " + option.lastName}</option>
                  }

                  return false
                })}
              </select>
            )
          }
        case "file":
          return (
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                name={name}
                onChange={(e) => this.uploadImage(e.target.files)}
                accept="image/*"
                autoComplete="off"
              />
              <label className="custom-file-label">{this.getImageText(this.state.logo, "logo")}</label>
            </div>
          )
        default:
          // Display value text if edit is set to false.
          return <p className="cdi-blue-txt team-identity-text">{data ? data : "-- No Data --"}</p>
      }
    } else {
      // Display value text if edit is set to false.
      if (name === "department") {
        return <p className="cdi-blue-txt team-identity-text">{data ? this.getDisplayName("department", data, array) : this.getLabelText("no_data")}</p>
      } else if (name === "coach") {
        return (
          <table className="w-100">
            <tbody>
              <tr>
                <td width="20%">
                  {data.avatar && data.avatar !== ""
                    ? <img src={data.avatar} style={{ maxWidth: "50px" }} alt="User Avatar"/>
                    : <FontAwesomeIcon icon={faCamera} className="ml-2 fa-2x" style={{ color: "darkgrey" }}></FontAwesomeIcon>
                  }
                </td>
                <td width="80%" className="text-left">
                  <p className="cdi-blue-txt team-identity-text">{data ? this.getDisplayName("user", data.id, array) : this.getLabelText("no_data_true_north")}</p>
                </td>
              </tr>
            </tbody>
          </table>
        )
      }

      if (type === "file") {
          if(data && data !== "" && data !== "null") {
            return <img src={data} style={{ maxWidth: "200px" }} alt="Team Logo"/>
          } else {
            return <FontAwesomeIcon icon={faImage} className="ml-2 fa-3x" style={{ color: "darkgrey" }}></FontAwesomeIcon>
          }
      }

      return <p className="cdi-blue-txt team-identity-text">{data ? data : this.getLabelText("no_data")}</p>
    }

  }

  getMultiSelect = (name, data, placeholder, array, level = null, meetingMembers = null) => {
    const { edit, create, userLoading, userType } = this.state
    let width
    if (edit || create) {
      const options = array.filter(option => !data.includes(option.id) && this.isAllowed(name, option.id)).map(({id, firstNames, lastName}) => ({ value: id, label: `${firstNames} ${lastName}` }))
      width = (level === "LVL2" && (name === "members" || name === "leaders")) ? "30%" : "50%";
      return (
        <Fragment>
          {
            data && data.length > 0
              ? data.map((item, i) => {
                const isMeetingMember = (level === "LVL2" && name === "members" && meetingMembers.find(member => member.id === item));
                return (
                  <FormGroup className="row" key={name + "-" + i}>
                    <Col className="mob-no-padding">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td width={width} className={isMeetingMember ? "bg-light-grey" : ""}>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  onChange={(e) => this.uploadImage(e.target.files, array.find(user => user.id === item))}
                                  accept="image/*"
                                  autoComplete="off"
                                />
                                <label className="custom-file-label">{this.getImageText(array.find(user => user.id === item), "avatar")}</label>
                              </div>
                            </td>
                            <td width={width} className={isMeetingMember ? "bg-light-grey" : ""}>
                              <select className="form-control cdi-ddl w-100 ml-0"
                                name={name}
                                value={item}
                                disabled={true}
                                >
                                <option value="1" disabled>{placeholder}</option>
                                {array.sort((a,b) => a.firstNames.localeCompare(b.firstNames)).map((option) => (
                                  <option key={name + "-" + option.id} value={option.id}>{option.firstNames + " " + option.lastName}</option>
                                ))}
                              </select>
                            </td>
                            {level === "LVL2" && name === "members" ? (
                              <td width="40%" className={"text-center" + (isMeetingMember ? " bg-light-grey" : "")}>
                                <Form.Check type="checkbox" checked={!!meetingMembers.find(member => member.id === item)} onClick={() => this.handleMeetingMembers(item)}/>
                              </td>
                            ) : null}
                            <td className={isMeetingMember ? "bg-light-grey" : ""}>
                              <Button variant="primary" className="btn-icons-container" size="sm" onClick={() => this.deleteItem(item, name)}>
                                <img src={Delete} alt="Delete" className="btn-icons"/>
                              </Button>
                            </td>
                            {level === "LVL2" && name === "leaders" ? (
                              <td width="40%">

                              </td>
                            ) : null}
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </FormGroup>
                )
              })
              : null
          }
          <FormGroup className="row" style={name === "members" ? {marginBottom: "160px"} : null}>
            <Col className="mob-no-padding">
              <CreatableSelect
                className="font-weight-normal our-leader-container"
                onChange={({ value }) => this.handleArray(name, value)}
                options={options}
                onCreateOption={async(value) => await this.createUser(name, value)}
                isLoading={userLoading && userType === name}
                value="Select..."
              />
              <small>{this.getLabelText("message_create_user_with_comma")}</small>
            </Col>
          </FormGroup>
        </Fragment>
      )
    }
    width = (level === "LVL2" && name === "members") ? "40%" : "80%";

    return (
      data && data.length > 0
        ? data.map((item, i) => {
          const isMeetingMember = (level === "LVL2" && name === "members" && meetingMembers.find(member => member.id === item));
          return (
            <table key={i} className="w-100">
              <tbody>
                <tr>
                  <td width="20%" className={isMeetingMember ? "bg-light-grey" : ""}>
                    {array.find(user => user.id === item) && array.find(user => user.id === item).avatar !== "" && array.find(user => user.id === item).avatar
                      ? <img src={array.find(user => user.id === item).avatar} style={{ maxWidth: "50px" }} alt="User Avatar"/>
                      : <FontAwesomeIcon icon={faCamera} className="ml-2 fa-2x" style={{ color: "darkgrey" }}></FontAwesomeIcon>
                    }
                  </td>
                  <td width={width} className={"text-left" + (isMeetingMember ? " bg-light-grey" : "")}>
                    <p className="cdi-blue-txt team-identity-text">{item ? this.getDisplayName("user", item, array) : this.getLabelText('no_data')}</p>
                  </td>
                  {level === "LVL2" && name === "members" ? (
                    <td width="40%" className={"pl-4" + (isMeetingMember ? " bg-light-grey" : "")}>
                      {meetingMembers.find(member => member.id === item) ? (<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>) : (<FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>)}
                    </td>
                  ) : null}
                </tr>
              </tbody>
            </table>

          )
        })
        : <p className="cdi-blue-txt team-identity-text">{this.getLabelText('no_data')}</p>

    )
  }

  getDisplayName = (type, id, data) => {
    const item = data.filter((option) => {
      return option.id === id
    })

    switch (type) {
      case "departmentType":
        return item.length > 0 && item[0].departmentType !== null ? item[0].departmentType.name : this.getLabelText('no_data')

      case "user":
        return item.length > 0 ? item[0].firstNames + " " + item[0].lastName : this.getLabelText('no_data')

      case "nickname":
        return item.length > 0 && item[0] && item[0].nickName ? item[0].nickName : this.getLabelText('no_data')

      case "department":
        return item.length > 0 ? item[0].name : this.getLabelText('no_data')
      default:
        return this.getLabelText('no_data')
    }
  }

  removeFromMemberList = (id) => {
    const { members } = this.state.team;

    if (this.getArrayIds(members).includes(id)) {
      let index = this.getArrayIds(members).indexOf(id)
      members.splice(index, 1)
    }
  }

  handleNicknameChange = (member, e) => {
    const { editNicknames, editableUsers } = this.state

    const index = editNicknames.findIndex(user => user.id === member.id)
    const user = editableUsers.find(user => user.id === member.id)
    user.nickName = e.target.value
    if(index >= 0) {
      editNicknames[index].nickName = e.target.value
    } else {
      editNicknames.push({
        id: member.id,
        firstNames: user.firstNames,
        lastName: user.lastName,
        isAdmin: user.isAdmin,
        nickName: e.target.value
      })
    }
    this.setState({
      editNicknames,
      editableUsers,
      modified: true
    })
  }

  handleChange = (e) => {
    e.persist()
    const { users, departments, departmentTypes } = this.state
    if (e.target.name === "coach") {
      this.removeFromMemberList(e.target.value)
    }

    let value
    if(e._targetInst.elementType === "select") {
      switch(e.target.name) {
        case "department":
          value = departments.filter(({ id }) => {
            return id === e.target.value
          })[0]

          break
        case "departmentType":
          value = departmentTypes.filter(({ id }) => {
            return id === e.target.value
          })[0]

          break
        default:
          value = users.filter(({ id }) => {
            return id === e.target.value
          })[0]

          break
      }
    } else {
      value = e.target.value
    }
    this.setState((prevState) => ({
      team: {
        ...prevState.team,
        [e.target.name]: value
      },
      error: null,
      modified: true
    }))
  }

  handleArray = (name, value) => {
    const data = this.state.team[name]

    if (name === "leaders") {
      this.removeFromMemberList(value)
    }
    const user = this.state.users.filter(({id}) => {
      return id === value
    })[0]
    data.push(user)
    this.setState((prevState) => ({
      team: {
        ...prevState.team,
        [name]: data
      },
      error: null,
      modified: true
    }))
  }

  handleMeetingMembers = id => {
    const meetingMembers = this.state.team.meetingMembers;
    const exists = meetingMembers.find(member => member.id === id)
    console.log(id)
    let members;
    if(exists) {
      members = meetingMembers.filter(member => member.id !== id)
    } else {
      members = [...meetingMembers, { id }]
    }

    this.setState((prevState) => ({
      team: {
        ...prevState.team,
        meetingMembers: members
      },
      error: null,
      modified: true
    }))
  }

  handleSubmit = async () => {
    const { team, create, logo, userAvatars } = this.state
    this.setState({
      loading: true,
      loadType: "edit"
    })
    const meetingMembers =  team.meetingMembers ? this.getArrayIds(team.meetingMembers) : null
    try {
      if(userAvatars.length > 0) {
        await Promise.all(userAvatars.map(async(avatar) => {
          await setUserAvatar(avatar.userId, avatar.avatar)
        }))
      }
      if(create) {
        const response = await createTeam(
          team.name,
          team.purpose,
          this.getArrayIds(team.leaders),
          this.getArrayIds(team.members),
          team.coach.id,
          team.department.id,
          logo,
          meetingMembers,
        )
        const id = response.data.createTeam.id
        await this.props.fetchTeams()
        this.props.selectTeam(id)
      } else {
        await updateTeam(
          team.id,
          team.name,
          team.purpose,
          this.getArrayIds(team.leaders),
          this.getArrayIds(team.members),
          team.coach.id,
          team.department.id,
          logo,
          meetingMembers,
        )
        await this.props.fetchTeams()
      }

      await this.fetchDepartments()
      if(userAvatars.length > 0) {
        await this.fetchUsers()
      }
      await this.props.fetchUser(true)

      this.setState({
        edit: false,
        modified: false,
        team: {},
        create: false,
        editNicknames: [],
        logo: "",
        userAvatars: []
      })
    } catch (error) {
      alert(error)
      displayErrorToast(error)
    }

    this.props.toggleDirtyState()
    this.setState({
      loading: false,
      loadType: ""
    })
  }

  handleEnable = (enable) => {
    const team = this.props.selectedTeam
    const users = this.state.users
    this.props.toggleDirtyState()
    let options = {
      edit: enable
    }

    if (enable) {
      options = {
        ...options,
        team: team,
        editableUsers: users,
        logo: team.logo
      }
    } else {
      options = {
        ...options,
        team: {},
        editableUsers: [],
        logo: ""
      }


    }

    this.setState(options)
  }

  handleCreate = (enable) => {
    this.props.toggleDirtyState()
    const users = this.state.users
    let options = {
      create: enable
    }
    if (enable) {
      options = {
        ...options,
        team: {
          id: "",
          name: "",
          department: [],
          purpose: "",
          coach: 1,
          leaders: [],
          members: []
        },
        editableUsers: users
      }
    } else {
      options = {
        ...options,
        team: [],
        editableUsers: []
      }
    }

    this.setState(options)
  }

  deleteItem = (id, name) => {
    if (window.confirm(this.getText('delete_department_prompt'))) {
      const { team } = this.state

      const array = team[name];

      const index = array.indexOf(array.filter((item) => {
        return item.id === id
      })[0])

      if (index > -1) {
        array.splice(index, 1);
      }

      this.setState((prevState) => ({
        team: {
          ...prevState.team,
          [name]: array
        },
        modified: true
      }))
    }
  }

  render() {
    const {
      edit,
      modified,
      users,
      departments,
      loading,
      loadType,
      create
    } = this.state
    let team
    if(edit || create) {
      team = this.state.team
    } else {
      team = this.props.selectedTeam
    }

    if(!team) return <>Select a team</>
  const level = (team && team.department && team.department.departmentType) ? team.department.departmentType.level : "DEP";

    return (
      <div className="team-identity-container">
        <SubHeaderComponent>
          {this.props.permissions.canEdit
            ? (
              <Row className="">
                <Col className="text-right active-edit">
                  {edit || create
                    ? (
                      <Fragment>
                        <button className="btn btn-primary btn-nrml" disabled={!modified || (loading && loadType === "upload")} onClick={this.handleSubmit}>
                          {loading && loadType === "edit"
                            ? (
                              <Fragment>
                                <span>{this.getLabelText("saving")}...</span>
                                <FontAwesomeIcon className="ml-2 fa-spin" icon={faSpinner} />
                              </Fragment>
                            )
                            : (
                              <Fragment>
                                <span>{this.getLabelText("save")}</span>
                                <FontAwesomeIcon className="ml-2" icon={faCheck} />
                              </Fragment>
                            )
                          }
                        </button>
                        {create
                          ? (
                            <button className="btn btn-danger ml-2 btn-nrml" onClick={() => this.handleCreate(false)}>{this.getLabelText('cancel')}<FontAwesomeIcon className="ml-2" icon={faBan} /></button>
                          )
                          : (
                            <button className="btn btn-danger ml-2 btn-nrml" onClick={() => this.handleEnable(false)}>{this.getLabelText('cancel')}<FontAwesomeIcon className="ml-2" icon={faBan} /></button>
                          )
                        }
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        {!this.props.selectedTeam || !this.props.selectedTeam.id ? null : <button className="btn btn-primary btn-nrml" onClick={() => this.handleEnable(true)}>{this.getLabelText('edit')}<FontAwesomeIcon className="ml-2" icon={faEdit} /></button>}
                        <button className="btn btn-primary btn-nrml ml-2" onClick={() => this.handleCreate(true)}>{this.getLabelText('add')}<FontAwesomeIcon className="ml-2" icon={faPlus} /></button>
                      </Fragment>
                    )
                  }
                </Col>
              </Row>
            ) : null
          }
        </SubHeaderComponent>
        <div className="grey-header-space container-left pl-plus-8">
          <Row className="mb-20">
            <Col xs={6}>
              <FormGroup className="row">
                <Col xl={4} md={12} className="mob-no-padding">
                  <label className="cdi-txt-lbl">{this.getLabelText('team_logo')}</label>
                </Col>
                <Col xl={8} md={12} className="mob-no-padding">
                  {this.getField("logo", "file", team.logo, "Upload Team Logo", null)}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col>
              <FormGroup className="row">
                <Col xl={4} md={12} className="mob-no-padding">
                  <label className="cdi-txt-lbl">{this.getLabelText('department_function')}</label>
                </Col>
                <Col xl={8} md={12} className="mob-no-padding">
                  {this.getField("department", "select", (team.department ? team.department.id : 1), "Select Department/Function", departments)}
                </Col>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="row">
                <Col xl={4} md={12} className="mob-no-padding">
                  <label className="cdi-txt-lbl">{this.getLabelText('department_type')}</label>
                </Col>
                <Col xl={8} md={12}>
                  {edit
                      ? (
                          <FormGroup className="row">
                            <Col xl={8} md={12} className="mob-no-padding">
                              <FormControl
                                  autoFocus
                                  type="text"
                                  disabled
                                  className="form-control"
                                  value={this.getDisplayName("departmentType", (team.department ? team.department.id : 1), departments)}
                                  onChange={(e) => this.handleChange(e)}
                                  autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                      )
                      : <p className="cdi-blue-txt team-identity-text d-inline">{this.getDisplayName("departmentType", (team.department ? team.department.id : 1), departments)}</p>
                  }
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col>
              <FormGroup className="row">
                <Col xl={4} md={12} className="mob-no-padding">
                  <label className="cdi-txt-lbl">{this.getLabelText('our_name')}</label>
                </Col>
                <Col xl={8} md={12} className="mob-no-padding">
                  {this.getField("name", "input", team.name, "Our Name")}
                </Col>
              </FormGroup>
            </Col>
            <Col>

            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col>
              <FormGroup className="row">
                <Col xl={4} md={12} className="mob-no-padding">
                  <label className="cdi-txt-lbl">{this.getLabelText('our_purpose')}</label>
                </Col>
                <Col xl={8} md={12} className="mob-no-padding">
                  {this.getField("purpose", "input", team.purpose, "Our Purpose")}
                </Col>
              </FormGroup>
            </Col>
            <Col>

            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col lg={2} md={12} className="mob-no-padding">
              <FormGroup className="row">
                <Col>
                  <label className="cdi-txt-lbl">{this.getLabelText('our_coach')}</label>
                </Col>
              </FormGroup>
            </Col>
            <Col xl={4} md={12} className="mob-no-padding">
              <FormGroup className="row">
                <Col className="no-padding">
                  <table className="w-100">
                    <tbody>
                    <tr>
                      <td width={edit || create ? "50%" : "20%"}>
                        <label className="cdi-txt-lbl">{this.getLabelText("avatar")}</label>
                      </td>
                      <td width={edit || create ? "50%" : "80%"}>
                        <label className="cdi-txt-lbl">{this.getLabelText("full_name")}</label>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  {this.getField("coach", "select", (team.coach ? team.coach : 0), "Select Coach", users)}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-20 mb-20 user-txt">
            <Col xl={2} md={12}>
              <FormGroup className="row">
                <Col className="mob-no-padding">
                  <label className="cdi-txt-lbl">{this.getLabelText('our_leaders')}</label>
                </Col>
              </FormGroup>
            </Col>
            <Col xl={4} md={12}>
              <FormGroup className="row">
                <Col className="no-padding">
                  <table className="w-100">
                    <tbody>
                    <tr>
                      <td width={edit || create ? ((level === "LVL2") ? "30%" : "50%") : "20%"}>
                        <label className="cdi-txt-lbl">{this.getLabelText("avatar")}</label>
                      </td>
                      <td width={edit || create ? ((level === "LVL2") ? "30%" : "50%") : ((level === "LVL2") ? "40%" : "80%")} className="text-left">
                        <label className="cdi-txt-lbl">{this.getLabelText("full_name")}</label>
                      </td>
                      {level === "LVL2" ? (
                        <td>

                        </td>
                      ) : null}
                    </tr>
                    </tbody>
                  </table>
                  {this.getMultiSelect("leaders", team.leaders.sort((a, b) => a.firstNames.localeCompare(b.firstNames)).map((leader) => leader.id), "Select Leader", users, level)}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-30 mb-30 user-txt">
            <Col xl={2} md={12}>
              <FormGroup className="row">
                <Col className="mob-no-padding">
                  <label className="cdi-txt-lbl">{this.getLabelText('our_members')}</label>
                </Col>
              </FormGroup>
            </Col>
            <Col xl={4} md={12}>
              <FormGroup className="row">
                <Col>
                  <table className="w-100">
                    <tbody>
                    <tr>
                      <td width={edit || create ? ((level === "LVL2") ? "30%" : "50%") : "20%"}>
                        <label className="cdi-txt-lbl">{this.getLabelText("avatar")}</label>
                      </td>
                      <td width={edit || create ? ((level === "LVL2") ? "30%" : "50%") : ((level === "LVL2") ? "40%" : "80%")} className="text-left">
                        <label className="cdi-txt-lbl">{this.getLabelText("full_name")}</label>
                      </td>
                      {level === "LVL2" ? (
                        <td>
                          <label className="cdi-txt-lbl">{this.getLabelText("meeting_member")}</label>
                        </td>
                      ) : null}
                    </tr>
                    </tbody>
                  </table>
                  {this.getMultiSelect("members", team.members.sort((a, b) => a.firstNames.localeCompare(b.firstNames)).map((member) => member.id), "Select Member", users, level, team.meetingMembers)}
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTeam: state.teamsReducer.selectedTeam,
  teams: state.teamsReducer.teams,
  user: state.userReducer.user
});

const mapDispatchToProps = {
  fetchUser,
  selectTeam,
  fetchTeams
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamIdentityScreen);
