const Constants = {
    Permission: {
        Consultant: "Consultant",
        CompanyAdmin: "Administrator",
        FunctionAdmin: "Function Admin",
        TeamAdmin: "Coach",
        User: "Team Member",
    },
    AWS: {
        region: 'us-east-1',
        accessKeyId: 'AKIASXFQ2SFJZ4FLYL3K',
        secretAccessKey: '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'
    }
}

export default Constants;